<template>
  <div class="container">
    <div class="lang">
      <el-select v-model="value" placeholder="请选择" @change="clickChange">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>

    <h2>UniLive软件许可及服务协议</h2>

    <p>
      导言<br />
      《UniLive软件许可及服务协议》（以下简称“本协议”）是PIX TECHNOLOGY PTE.
      LTD公司下的UniLive软件（以下简称“UniLive”或“我们”）与您（以下亦称“用户”）之间就您下载、安装、登录、使用本软件，以及使用UniLive相关服务所订立的协议。请您仔细阅读本协议内容。如您对本协议内容或页面提示信息有疑问，请勿进行下一步操作。您可通过UniLive平台的官方客服进行咨询，以便我们为您解释和说明。您通过页面点击或直接开始使用UniLive软件相关服务等其他方式确认即表示您已同意本协议。<br />
    </p>
    <p>
      如您是未满18周岁的未成年人或者因智力、精神健康状况而不具有完全民事行为能力，请您在监护人的指导和陪同下阅读本协议及其他相关协议，并特别注意未成年人使用相关条款，并征得监护人的同意后使用UniLive软件及相关服务。特别注意的是，在未征得监护人明示同意的情况下，请您不要使用UniLive提供的充值消费相关服务，也请监护人切实履行监护职责。
    </p>
    <h3>本协议将帮助您了解以下内容</h3>
    <p>一、定义</p>
    <p>二、协议的适用范围及修订</p>
    <p>三、UniLive软件许可与使用规范</p>
    <p>四、账号的注册、使用和注销</p>
    <p>五、用户个人信息保护</p>
    <p>六、未成年人使用条款</p>
    <p>七、用户行为规范</p>
    <p>八、数据使用规范</p>
    <p>九、依法纳税义务</p>
    <p>十、知识产权</p>
    <p>十一、推广</p>
    <p>十二、关于单项服务的特殊约定</p>
    <p>十三、服务的变更、中断和终止</p>
    <p>十四、免责声明</p>
    <p>十五、违约处理</p>
    <p>十六、其他</p>
    <h3>一、定义</h3>
    <p>
      1.UniLive平台，是指UniLiveAPP，以及后续可能开通的其他网站、网页、客户端、小程序和随技术发展出现的新的服务载体。
    </p>
    <p>
      2.本服务/服务，是指通过UniLive平台向用户提供的相关服务（包括但不限于通过提供信息储存空间，进而向用户提供网络音视频、网络直播、搜索等，以及由此或为此提供的其他相关服务）。
    </p>
    <p>
      3.UniLive用户/用户，包括注册用户及未注册用户。注册用户是指创建一个UniLive账号来使用UniLive平台的某些功能和服务的用户；凡未注册我们的产品及/或服务的用户，自开始使用我们的产品及/或服务时即成为我们的非注册用户，在使用过程中须遵循本协议中除注册用户专属约定以外的其他所有条款。
    </p>
    <p>
      4.UniLive账号/账号，是指您在UniLive平台所注册及使用的账号，您注册账号后可以通过UniLive平台实现包括但不限于直播/短视频拍摄、发布、观看、评论、分享、搜索等功能中的一项或多项。
    </p>
    <p>
      5.UniLive平台规则，包括所有UniLive已经发布或将来可能发布、修订的各类服务条款、规则、规范、规则解读、实施细则、通知、公告等规则性文件，具体以相关页面展示为准。需要特别提醒您的是，您在使用UniLive平台中的某些特定产品及/或服务时，请特别关注相关的服务条款或规则。您同意本协议或实际使用该等服务的，即视为您同意前述条款。
    </p>

    <h3>二、协议的适用范围及修订</h3>
    <p>
      1.协议的适用主体范围,请您知悉，UniLive平台是一个综合性服务平台，您可根据使用的相应服务所签署的协议或对应服务所公示的信息确定与您履约的UniLive平台运营者主体。
      本协议项下，UniLive平台运营者可能根据UniLive平台的业务调整而发生变更，变更后的UniLive平台运营者与您共同履行本协议并向您提供服务。UniLive平台运营者还有可能因为UniLive平台提供新的服务而新增，如您使用新增的服务，视为您同意新增服务对应的UniLive平台运营者与您共同履行本协议。当您因使用UniLive平台内的某一项服务发生争议时，争议相对方即为您具体使用的服务所对应的UniLive平台运营者。
    </p>
    <p>
      2.协议的范围，
      本协议包括UniLive平台规则，是本协议不可分割的一部分，与本协议具有同等法律效力。
      此外，如您在我们平台上使用第三方提供的产品及/或服务时，除遵守本协议约定外，还应遵守第三方的服务条款。
    </p>
    <p>
      3.协议的修订，
      UniLive可能会依据法律法规、国家政策、技术条件、产品功能等变化需要而适时修订本协议，UniLive会将修订后的协议予以发布。前述内容一经正式发布，UniLive将以适当的方式送达用户（弹窗提示、网站公布、系统通知等，具体以实际为准）。如您对修订的协议内容或页面提示信息有疑问，您可以通过UniLive平台的官方客服进行咨询，以便我们为您解释和说明。您通过页面点击或其他方式（如继续使用）确认即表示您已同意修订后的协议。
    </p>

    <h3>三、UniLive软件许可与使用规范</h3>
    <p>1.软件许可的范围</p>
    <p>
      1.1
      您使用本服务可能需要下载UniLive软件，对于UniLive软件及其技术（如适用），UniLive仅授予您一项个人的、可撤销的、不可转让及非排他性的许可。您仅可以为使用本服务之目的下载、安装、使用、运行UniLive软件。
    </p>
    <p>
      1.2
      本条及本协议其他条款未明示授权的其他一切权利仍由UniLive保留，您在行使这些保留权利时须另外取得UniLive的书面许可。UniLive如果未行使任何保留权利，并不构成对保留权利的放弃。
    </p>
    <p>
      2.软件的获取
    </p>
    <p>
      2.1 您使用UniLive软件及相关服务，可以通过预装、UniLive已授权的第三方（例如App Store）下载等方式获取UniLive应用程序。
    </p>
		<p>
      2.2 如果您从非UniLive或非经UniLive授权的第三方处获取UniLive软件，UniLive无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
    </p>
		<p>
      3.软件的安装与卸载
    </p>
		<p>
			3.1 UniLive可能为不同的终端、系统等开发了不同的软件版本，请您根据实际情况选择下载合适的版本进行安装。
    </p>
		<p>
      3.2 下载安装程序后，请您按照该程序提示的步骤正确安装。
    </p>
		<p>
      3.3 如果您不再需要使用本软件或需要安装新版软件，可以自行卸载。如果您愿意帮助UniLive改进产品服务，我们非常期待您能告知卸载的原因。
    </p>
		<p>
      4.软件的更新
    </p>
		<p>
      4.1 为了改善用户体验、完善服务内容，UniLive将不断努力开发新的服务，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。
    </p>
		<p>
      4.2 为了保证本软件及服务的安全性和功能的一致性，UniLive有权在不向您特别通知的情况下而对软件进行更新，或对软件的部分功能效果进行改变或限制。
    </p>
		<p>
      4.3 本软件新版本发布后，旧版本软件的全部或部分功能可能无法使用。UniLive不保证旧版本软件继续可用及提供相应的客户服务，请您及时下载最新版本。
    </p>


    <h3>四、账号的注册、使用和注销</h3>
    <p>
      1.注册
您可以创建一个UniLive账号来使用我们平台的某些功能和服务。您可以直接在我们平台完成UniLive账号的注册/登录。

    </p>
    <p>
      2.使用
    </p>
    <p>
      2.1 用户有责任妥善保管UniLive账号信息及账号密码的安全，用户需要对UniLive账号以及密码下的行为承担法律责任。用户同意在任何情况下不向他人透露账号及密码信息，请您知悉，UniLive也不会主动向您索要账号密码。当在您怀疑账号出现被盗等不安全情况时，请您立即通知UniLive。关于账号的安全，UniLive将会在目前技术水平下努力保护您的账号在服务器端的安全，并不断更新技术措施。因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，您可以通过司法、行政等救济途径向侵权行为人追偿。如您需要UniLive协助的，UniLive会在届时的法律框架下尽力协助。
    </p>
    <p>
      2.2 UniLive账号的所有权归北京UniLive科技有限公司所有，用户完成申请注册手续后，所注册UniLive账号仅限您本人使用。同时，由于您的账号行为（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）均代表您本人行为，您应妥善保管您的账号信息及密码并对您账号行为的结果负责，未经UniLive书面同意，您不得以任何形式出借、出租、赠与、转让、售卖或以其他方式许可他人使用UniLive账号。如您的账号遭到不属于您本人的操作或使用，请您立即通知UniLive，以避免扩大的损失和后果；如UniLive判断您UniLive账号的使用可能危及您的账号安全及/或UniLive软件信息安全的，基于维护您的财产及信息安全考虑，您同意UniLive可中止提供相应服务直至您本人通过可信方式要求恢复且该账号对UniLive软件信息安全的威胁已经消除。
    </p>
    <p>
      2.3 您理解并承诺，您所设置的UniLive账号信息不得违反国家法律法规、政策及UniLive所提供的服务的相关规则。您设置的账号名称、UniLive号、头像、个人介绍及个人资料等注册信息中不得出现违法或不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、商标、字号、肖像、头像等具有独特的识别性元素或足以让人混淆的方式）注册UniLive账号或设置UniLive账号信息，不得通过频繁注册、批量注册等行为恶意注册UniLive账号。
    </p>
		<p>
      2.4 根据国家相关法律法规规定及监管政策要求，为便于公众为公共利益实施监督及营造良好的社区秩序，UniLive会在您的账号个人信息页面等位置展示您所使用账号在合理范围内的互联网协议（IP）地址归属地等信息，具体展示位置、形式、信息类型等展示相关安排以相关法律法规规定和实际展示情况为准，对此您完全知悉并同意。
    </p>
		<p>
      2.5 为保护账号安全，防止账号被盗等情况发生，我们可能会不时采用一种或多种方式对注册用户进行用户身份验证（如邮件验证等），如用户未成功通过验证，我们有合理理由怀疑该账号出现被盗等不安全情况，并可能视情节严重情况而单方决定是否中止或终止向该账号继续提供我们的产品及/或服务及/或采取进一步措施。您同意，我们采取前述措施是为了保护用户账号安全，我们无需因此承担不合理责任。
    </p>
		<p>
      2.6 用户注册UniLive账号后如果连续两个月不登录该账号，UniLive有权对账号进行冻结、回收、替换、注销，同时有权采取删除、清空该账号在UniLive软件数据库中的任何记录（包括但不限于注册信息、虚拟资产等）等清理措施，以免造成资源浪费，由此带来的任何损失均由用户自行承担。
    </p>
		<p>
			2.7 如您的账号被采取冻结措施的，您可以通过UniLive平台提供的其他验证方式重新激活您的账号。
    </p>
		<p>
      3.注销
我们为您提供UniLive账号注销功能，您可以通过UniLive平台提供的在线注销方式、联系我们的客服或通过其他我们提供的方式注销您的账号。

    </p>

    <h3>五、用户个人信息保护</h3>
		
    <p>
      1.我们深知个人信息对您的重要性，因此我们非常重视保护您的隐私和个人信息，亦将您的个人信息以高度审慎的义务对待和处理。在您使用我们的产品及/或服务的过程中，我们将采用相关技术措施及其他安全措施来保护您的个人信息。
    </p>
    <p>
      2.在您注册账号或使用相关服务的过程中，我们需要您配合提供一些必要的信息，例如您的邮箱等，若您不同意对相关服务所必要的个人信息进行授权，则无法使用该等服务或在使用过程中受到限制。
    </p>
    <p>
      3.我们非常重视您对个人信息的管理，并尽全力保护您对于个人信息的查阅、复制、更正、补充、删除、撤回同意授权及注销账号、投诉举报等权利，以使您有能力了解并保障您的个人信息安全。
    </p>
    <p>
			4.我们非常注重对未成年人个人信息的保护。若您是未满18周岁的未成年人，在使用UniLive的服务前，应事先取得您父母或其他监护人的同意。
    </p>
    <p>
      5.对于UniLive具体会如何收集、使用、存储和保护您的个人信息及您享有何种权利，请您访问UniLive隐私政策予以进一步了解。
    </p>

    <h3>六、未成年人使用条款</h3>
    <p>
      1.若您是未满18周岁的未成年人，您应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用UniLive软件及相关服务。
    </p>
    <p>
      2.UniLive重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导下正确使用UniLive软件及相关服务。
    </p>
    <p>
			3.未成年人使用本软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯，要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。
    </p>
    <p>
      4. 您的被监护人在使用UniLive提供的服务时可能使用充值、打赏等功能。您作为监护人，请您保管好您的支付账户以避免被监护人在未取得您同意的情况下通过您的支付账户使用充值、打赏功能。UniLive愿与您一起努力避免此类行为。
    </p>
    <p>
      5.为更好地保护未成年人隐私权益，UniLive提醒您慎重发布包含未成年人影像的内容，一经发布，即视为您已取得权利人同意展示未成年人的肖像、声音等信息，且允许UniLive依据本协议使用、管理该与未成年人相关的内容。如权利人向UniLive通知您发布的内容侵犯未成年人权利，或基于其他的保护未成年人权利的考虑，UniLive有权对您发布的内容进行处理并通知您。
    </p>

    <h3>七、用户行为规范</h3>
    <p>
      1.用户行为要求，您应当对您使用UniLive软件及相关服务的行为负责，除非法律允许或经UniLive事先书面许可，您使用UniLive软件及相关服务不得具有下列行为：

    </p>
    <p>
      1.1 使用未经UniLive授权或许可的任何插件、外挂、系统或第三方工具对UniLive软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。
    </p>
    <p>
      1.2 利用或针对UniLive软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：
    </p>
    <p>
      1.2.1 非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；
1.2.2 提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；
1.2.3 明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；
1.2.4 使用未经许可的数据或进入未经许可的服务器/账号；
1.2.5 未经允许进入公众计算机网络或他人计算机系统并删除、修改、增加存储信息；
1.2.6 未经许可，企图探查、扫描、测试UniLive系统或网络的弱点或其他实施破坏网络安全的行为；
1.2.7 企图干涉、破坏UniLive系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
1.2.8 伪造TCP/IP数据包名称或部分名称。

    </p>
    <p>
			1.3 对UniLive软件进行反向工程、反向汇编、编译或以其他方式尝试发现本软件的源代码。
    </p>
		<p>
			1.4 对UniLive软件或UniLive软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及UniLive软件运行所必需的系统数据，进行获取、复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂、群控或非经UniLive授权的第三方工具/服务接入UniLive软件和相关系统。
    </p>
		<p>
			1.5 通过修改或伪造UniLive软件运行中的指令、数据，增加、删减、变动UniLive软件的功能或运行效果，或将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的。
    </p>
		<p>
			1.6 删除UniLive软件（及其副本）和相关内容（如短视频）上关于知识产权的信息，或修改、删除、避开为保护知识产权而设置的任何技术措施。
    </p>
		<p>
			1.7 对UniLive拥有知识产权的内容或UniLive平台内其他用户发布的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、垂直搜索、建立镜像站点等。
    </p>
		<p>
			1.8 利用UniLive账号参与任何非法或有可能非法（由UniLive判断）的活动或交易，包括传授犯罪方法、出售任何非法药物、洗钱活动、诈骗等。
    </p>
		<p>
			1.9 违反法律法规、本协议、UniLive平台规则及侵犯他人合法权益的其他行为。
如果UniLive有合理理由认为您的任何行为违反或可能违反上述约定的，UniLive可独立进行判断并采取必要措施进行处理，紧急情况时可在不事先通知的情况下终止向您提供服务，并依法依约追究相关责任。
    </p>
		<p>
			2.信息内容规范
    </p>
		<p>
			2.1 本条所述信息内容是指用户使用本软件及服务过程中所制作、复制、发布、传播的任何内容，包括但不限于UniLive账号的头像、昵称、个人介绍等个人主页所展示的信息，或发布、传播的文字、图片、音视频、直播等信息，以及其他使用UniLive账号或本软件及服务所产生的内容。
    </p>
		<p>
			2.2 您理解并同意，UniLive一直致力于为用户提供文明健康、规范有序的网络环境，您不得利用UniLive账号或本软件及服务制作、复制、发布、传播如下干扰UniLive正常运营，以及侵犯其他用户或第三方合法权益的内容。 
    </p>
    <p>2.3 UniLive视频上传的基本规则</p>
		<p>
			内容规范（社区准则）
		</p>
		<p>
			禁止暴力内容：不得上传展示暴力、伤害、虐待或恐怖主义相关的内容，包括真实或模拟的暴力行为。
		</p>
		<p>
			禁止色情或露骨内容：禁止裸露、性暗示、色情或任何形式的成人内容
		</p>

		<p>
			禁止仇恨言论：不得发布针对种族、宗教、性别、性取向、国籍等群体的歧视性、攻击性或煽动性内容。
		</p>
		<p>
			禁止欺凌和骚扰：不得上传羞辱、威胁或骚扰他人的内容，包括针对儿童的不当行为。
		</p>
		<p>
			禁止危险行为：不得展示可能导致伤害的危险挑战、恶作剧或非法活动（如吸毒、武器使用等）
		</p>
		<p>
			3.活动参与规范
    </p>
		<p>
			UniLive或UniLive平台上的第三方会不定期开展各类活动，参与活动前，您应当进入相应活动页面仔细阅读、充分理解活动规则，您参与活动的，视为您已充分阅读、理解该活动规则，并自愿受活动规则的约束。阅读活动规则的过程中，如果您不理解或不同意其中的任何内容，请停止参与相关活动。 
请您知悉，对于利用不正当手段参与活动的用户（指违反本协议或者活动规则中禁止性规定的活动参与者），风控系统将会自动甄别并剔除。您同意，UniLive有权根据自身平台的大数据分析能力、技术识别能力独立判断相关行为是否违规，并对利用不正当手段参与活动的用户取消其活动参与资格、评奖资格及获奖资格，对利用不正当手段获利的结果不予认可，同时有权追回已经发放的奖励。
    </p>
		<p>
			4. 对自己行为负责
    </p>
		<p>
			您理解并同意，您应为自己注册账号下的行为负责，包括您所发表任何内容、评论及点赞、推荐、搜索等行为，以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的合法性、正确性、完整性或实用性的依赖而产生的风险。UniLive无法且不会对因前述风险而导致的损失或损害承担责任。
    </p>
		<p>
			UniLive尊重并保护您及他人的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用UniLive软件及相关服务时上传的文字、图片、音视频、直播、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，UniLive有权在收到权利方或相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行处理并承担可能因此产生的全部法律责任；如因您的侵权行为导致UniLive或其关联方遭受损失的（包括但不限于经济、商誉等损失），您还应足额赔偿UniLive或其关联方遭受的全部损失。
    </p>

    <h3>八、数据使用规范</h3>
    <p>
			1.未经UniLive书面许可，用户不得自行或授权、允许、协助任何第三方对UniLive软件及相关服务中信息内容进行如下行为：
    </p>
    <p>
      1.1 复制、读取、采用UniLive软件及相关服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；
    </p>
    <p>
      1.2 擅自编辑、整理、编排UniLive软件及相关服务的信息内容后在UniLive软件及相关服务的源页面以外的渠道进行展示；
    </p>
    <p>
      1.3 采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三方对UniLive软件及相关服务的信息或内容进行流量、浏览量引导、转移、劫持等不当行为；
    </p>
    <p>
      1.4 其他非法获取UniLive软件及相关服务的信息内容的行为。
    </p>
		<p>
      2.经UniLive书面许可后，用户对UniLive软件及相关服务的信息和内容的分享、转发等行为，还应符合以下规范：
    </p>
		<p>
			2.1 对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、浏览量等相关数据，未经UniLive事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三方；
    </p>
		<p>
      2.2 不得对UniLive软件及相关服务的源网页进行任何形式的任何改动，包括但不限于UniLive软件及相关服务的首页（profile）链接、广告系统链接等入口，也不得对UniLive软件及相关服务的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；
    </p>
		<p>
			2.3 应当采取安全、有效、严密的措施，防止UniLive软件及相关服务的信息内容被第三方通过包括但不限于“蜘蛛”（spider）程序等任何形式进行非法获取；
    </p>
		<p>
      2.4 不得把相关数据内容用于UniLive书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用；
    </p>
		<p>
      2.5 用户向任何第三方分享、转发、复制UniLive软件及相关服务信息内容的行为，还应遵守UniLive为此制定的其他规范和标准。
    </p>

    <h3>九、依法纳税义务</h3>
    <p>
      您在UniLive平台产生的收入或获得的奖励等有关收益（以下简称“有关收益”）可提现至您本人的支付账户。根据国家相关法律、行政法规以及有关税收规范性文件的规定，您在UniLive平台产生的有关收益需要依法缴纳相关税费或办理纳税申报。您理解并同意，UniLive平台依法按照税务机关的要求为您向税务机关代扣代缴税款或代为办理纳税申报时，需要收集或使用您的个人信息和涉税资料（包括不限于真实姓名、身份证信息以及联系方式等），您应当配合UniLive平台积极履行纳税义务。
    </p>

    <h3>十、知识产权</h3>
    <p>
      1.UniLive在本软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于UniLive所有。UniLive提供本服务时所依托的软件的著作权、商标权、专利权、商业秘密及其他知识产权均归UniLive所有。未经UniLive许可，任何人不得擅自使用与转让（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载本软件及相关服务中的内容）。
    </p>
    <p>
      2.您保证，在使用本软件及相关服务时发布上传的文字、图片、视频、音频等均由您原创或已获合法授权（含转授权），您通过UniLive软件上传、发布所产生内容的知识产权归属您或原始著作权人所有。
    </p>
    <p>
			3.为使您的作品得到更好地分享及推广，扩大您及您的作品的的影响力，除非您与UniLive另行达成了相反的书面约定，您知悉、理解并同意将您在使用UniLive软件及相关服务时发表/上传的内容（包括但不限于文字、图片、视频、音频等成果，以及前述成果中包括的人物肖像权、表演者权、音乐、声音、对话等内容及元素）的著作权及相关竞争性权益，在全球范围内免费地、非独家地、包含多层次转许可权利地授予UniLive及其关联公司使用（包括但不限于复制权、改编权、翻译权、汇编权、表演权、信息网络传播权、广播权、制作衍生品等），前述授权使用的范围包括但不限于UniLive平台或其他与UniLive合作的第三方平台、应用程序、产品或终端设备、网页、页面等现有及未来出现的载体。您同意UniLive可以自行或许可第三方为宣传推广或产品/功能升级、新产品/功能研究之目的使用或开发上述内容（全部或部分），并承诺不会就UniLive对于前述内容的使用或开发而主张人身性权利或报酬。基于部分服务功能的特性，您通过UniLive软件上传的内容可供其他UniLive用户使用UniLive软件创作并发布内容时使用，用户根据前述约定再创作的作品仅能于UniLive平台或UniLive合作的第三方平台传播，未经UniLive同意，不得用于任何商业用途。若您不希望UniLive继续使用全部或部分已授权内容，可通过UniLive公示的方式通知UniLive撤销该授权。
    </p>
    <p>
      4.为提高您作品曝光量及发布效率，您同意将您在使用UniLive软件及相关服务时所发布的全部内容均授权UniLive以您的账号自动同步发布至北京UniLive科技有限公司或其关联公司运营的其他客户端、网站、网页及小程序等服务载体，您账号的头像、昵称等公开非保密信息可能会一并同步。若您不希望继续授权UniLive自动同步所发布内容，可通过UniLive软件相关操作页面的提示自主撤销授权或通过其他公示的方式通知UniLive撤销该授权。
    </p>
    <p>
      5.您确认并同意授权UniLive以UniLive自己的名义或委托第三方对侵犯您上传发布的享有知识产权或已获合法授权的内容进行代维权，届时您需应UniLive要求配合出具相应的书面证明文件。维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，UniLive有权对维权事宜作出决策并独立实施。
    </p>
		<p>
			6.如您发现本软件及相关网站内存在任何侵犯您权利的内容，请立即通过邮箱【unilive.team@gmail.com】通知UniLive，并同时提供您有关权利的初步证据，UniLive会根据法律规定及时处理您的投诉。
    </p>
		<p>
      7.UniLive为本软件及相关服务开发、运营提供技术支持，并对本软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。
    </p>
		<p>
      8.请您在任何情况下都不要私自使用UniLive的包括“UniLive科技”“UniLive”“unilive.com”等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经UniLive事先书面同意，您不得将本条款前述标识以单独或结合等任何方式展示、使用，或实施其他处理该些标识的行为。由于您违反本协议使用UniLive公司上述商标、标识等给UniLive或他人造成损失的，由您承担全部法律责任。
    </p>

    <h3>十一、推广</h3>
    <p>
      1.您理解并同意，我们在提供UniLive软件及相关服务的过程中，保留自行或由我们合作伙伴以多种方式投放和/或挂载商业性广告（包括广告链接）或其他任何类型的商业及非商业性信息（以上统称“推广信息”）的权利。该权利包括但不限于UniLive可在您上传、传播的内容以及账号等UniLive平台的任何位置投放和/或挂载推广信息。在符合法律法规的前提下，该等推广信息投放和/或挂载的形式、时长、位置、内容不受任何第三方的干预。
    </p>
    <p>
      2.关于商业性广告
    </p>
    <p>
      2.1 如您不愿意接收具有相关性的广告，您有权对该广告信息选择“不感兴趣”，该广告同类广告的推送将会减少。
    </p>
    <p>
			2.2 我们会依照法律规定履行广告相关义务，但您理解并同意，广告合作方和广告内容非由我们控制，您对广告内容应审慎判断其真实性、可靠性。您通过我们的产品与/或服务与广告合作方进行任何形式的行为或产生任何形式的纠纷，由您自行负责并解决，除法律法规规定应由我们承担的责任外，我们不承担任何额外责任，但根据需要会依法提供必要的协助。
    </p>
    <p>
      2.3 如您不愿意接收UniLive推送通知服务的，您有权在手机系统通知管理中自行关闭该服务。
    </p>

    <h3>十二、关于单项服务的特殊约定</h3>
    <p>
			1.UniLive软件及相关服务中包含UniLive以各种合法方式获取的信息或信息内容链接，同时也包括UniLive及其关联公司合法运营的其他单项服务，UniLive可能会不时地增加、减少或改动这些特别板块的设置及服务。您可以在UniLive软件中开启和使用上述单项服务功能。某些单项服务可能需要您同时接受就该服务特别制定的协议或其他约束您与该项服务提供者之间的规则。UniLive将以醒目的方式提供这些协议、规则供您查阅。一旦您同意协议或开始使用上述服务，则视为您同时接受有关单项服务的相关协议、规则的约定内容。
    </p>
    <p>
      2.您在UniLive软件中使用第三方提供的软件及/或相关服务时，除遵守本协议及UniLive平台规则外，还应遵守第三方的协议、相关规则。如因第三方软件及/或相关服务产生的争议、损失或损害，由您自行与第三方解决，UniLive并不就此而对您或任何第三方承担责任。
    </p>

    <h3>十三、服务的变更、中断和终止</h3>
    <p>
      1.在法律法规允许的范围内，我们可能会根据我们的业务发展情况，暂时或永久地变更或终止我们的产品及/或服务（或任何一部分），在事先合理期限内以站内信、更新/终止公告等书面形式告知后，该等变更或终止对您和任何第三方不承担违约责任
    </p>
    <p>
			2.除上述的情形外，在发生如下情况时，我们有权中止或终止向您提供产品及/或服务：
    </p>
    <p>
			2.1 您主动提出要求的；
    </p>
    <p>
      2.2 您存在或我们经独立判断后认为您存在任何违反国家法律法规或监管政策、违反本协议或有损害我们的声誉、权益的行为的；
    </p>
    <p>
			2.3 根据法律法规、监管政策的规定或有权机关的要求；
    </p>
		<p>
			2.4 为维护账号与系统安全等紧急情况之需要；
    </p>
		<p>
			2.5 不可抗力（不可抗力是指不能预见、不能避免且不能克服的客观情况，鉴于互联网之特殊性质，本协议所称不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制而造成之暂时关闭、病毒侵袭等影响互联网正常运行之情形）；
    </p>
		<p>
      2.6 其他无法抗拒的情况。
    </p>
		<p>
      3.发生上述所述之终止的情况时，您与我们均认可如下处理方式：
    </p>
		<p>
      3.1 如您在我们平台内有正在进行中的交易，我们届时将视情况进行合理处理；
    </p>
		<p>
      3.2 如因您违反本协议导致终止，我们有权视情况要求您承担相应的违约责任；
    </p>
		<p>
      3.3 除法律法规另有规定或我们另有说明外，我们无需向您和第三方承担任何责任。
    </p>

    <h3>十四、免责声明</h3>
    <p>
      1.您理解并同意，UniLive软件及相关服务可能会受多种因素的影响或干扰，除法律法规明确规定外，UniLive不保证（包括但不限于）：
    </p>
    <p>
      1.1 UniLive软件及相关服务完全适合用户的使用需求；
    </p>
    <p>
      1.2 UniLive不受干扰，完全及时、安全、可靠或不出现错误；及用户经由UniLive取得的任何软件、服务或其他材料符合用户的期望；
    </p>
    <p>
      1.3 UniLive软件中任何错误都将能得到更正。
    </p>
    <p>
      2.对于涉嫌借款或其他涉财产的网络信息、账户密码、广告或推广等信息的，请您谨慎对待并自行进行判断，否则您因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，UniLive不承担任何直接、间接、附带、特别、衍生性或惩罚性的法律责任。
    </p>
		<p>
      3.您理解并同意，在使用UniLive软件及相关服务过程中，可能遇到不可抗力等因素。出现不可抗力情况时，UniLive将努力在第一时间及时修复，但因不可抗力给用户造成了损失，用户同意UniLive不承担责任。
    </p>
		<p>
      4.UniLive依据本协议约定获得处理违法违规内容的权利，该权利不构成UniLive的义务或承诺，UniLive不能保证及时发现违法行为或进行相应处理。
    </p>
		<p>
      5.您理解并同意，UniLive不提供有关本服务的任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。
    </p>
		<p>
			6.您理解并同意，本协议系双方基于遵守国家法律法规、维护公序良俗、保护他人合法权益以及提高UniLive用户使用体验的原则下起草订立。对于协议履行过程中涉及UniLive须履行审查、监管或其他平台义务的事项，UniLive将在能力范围内尽最大的努力按照相关法律法规对相关工作进行执行、判断，但并不保证UniLive判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。
    </p>

    <h3>十五、违约处理</h3>
    <p>
      1.针对您违反本协议或其他UniLive平台规则的行为，UniLive有权独立判断并视情况采取警示提醒、限期改正、限制账号部分或全部功能、暂停使用直至终止提供服务、关闭账号、禁止重新注册等措施，对于因此而造成您无法正常使用账号及相关服务、无法正常获取您账号内资产或其他权益等后果或损失（包括但不限于用户资料、虚拟资产及相关数据、产品或服务等的清空或无法使用等损失），由您自行承担，UniLive不承担法律责任。UniLive有权公告违规事件及其处理结果，且有权根据实际情况决定是否恢复相关账号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为，UniLive将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等，对已删除内容UniLive有权不予恢复。
    </p>
    <p>
			2.因您违反本协议或其他UniLive平台规则规定，引起第三方投诉或诉讼索赔的，您应当自行处理并承担可能因此产生的全部法律责任。因您的违法或违约等行为导致UniLive或关联方向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿UniLive或其关联方因此遭受的全部损失。
    </p>


    <h3>十六、其他</h3>
    <p>
      1.本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。
    </p>
    <p>
			2.本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
    </p>

   
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "中文",
          label: "中文",
        },
        {
          value: "English",
          label: "English",
        },
        {
          value: "한국인",
          label: "한국인",
        },
        {
          value: "日本語",
          label: "日本語",
        }, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
      ],
      value: "",
      language: "",
    };
  },
  created() {
    // url获取参数
    this.language = this.$route.query.language;
    if (this.language == "zh_CN") {
      this.$router.push({
        path: "/UserServiceAgreementZh",
      });
    }
    if (this.language == "en_US") {
      this.$router.push({
        path: "/UserServiceAgreementEn",
      });
    }
    if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/UserServiceAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/UserServiceAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/UserServiceAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/UserServiceAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/UserServiceAgreementTh'
			})
		}
  },
  methods: {
    clickChange(value) {
      this.value = value;
      if (value == "中文") {
        this.$router.push({
          path: "/UserServiceAgreementZh",
        });
      }
      if (value == "English") {
        this.$router.push({
          path: "/UserServiceAgreementEn",
        });
      }
      if (value == "한국인") {
        this.$router.push({
          path: "/UserServiceAgreementKo",
        });
      }
      if (value == "日本語") {
        this.$router.push({
          path: "/UserServiceAgreementJa",
        });
      }
      if (value == 'Русский') {
				this.$router.push({
					path: '/UserServiceAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/UserServiceAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/UserServiceAgreementTh'
				})
			}
    },
  },
};
</script>

<style>
.container {
  padding: 0.1rem 0.3rem;
}

.lang {
  text-align: right;
  margin-bottom: 0.3rem;
}

h1,
h2 {
  color: #333;
  font-size: 0.5rem;
}

h3 {
  margin-top: 20px;
  font-size: 0.4rem;
}

p {
  margin-bottom: 15px;
  font-size: 0.3rem;
}

li {
  font-size: 0.3rem;
}
</style>
